@import "~Stylesheets/base";

.NAICSCodeSelectSearch {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 58.33%;
  height: 32px;

  @media (max-width: @screen-sm-min) {
    width: 100%;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: @gray200;
  }

  &--error {
    border-color: @red900;
    &.displayError {
      border: 1px solid @red900;
    }
  }

  &__select__menu {
    position: relative !important;
    width: 100%;
  }

  &__select__menu-list {
    max-height: 55vh;
  }

  &__select__option {
    transition: background 60ms;
    overflow: hidden;
    background: transparent !important;
    border-bottom: 1px solid @gray200;

    &:hover {
      transition-delay: 60ms;
      background: @blue200 !important;
    }
  }

  &__select__option--is-focused {
    background: none;
  }

  &__description,
  &__examples {
    // truncate text to 3lines
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    -webkit-box-pack: end;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__select__value-container {
    cursor: text;
  }
}
